<template>
	<div class="content-div">
		<el-row :gutter="20">
			<el-col :span="6">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>字典项</span>
						<el-button-group style="float: right;">
							<el-button type="primary" v-if="isAuth('sys:dict:type:save')" icon="el-icon-plus" @click="handleItemAdd"></el-button>
							<el-button type="primary" v-if="isAuth('sys:dict:type:update')" icon="el-icon-edit" @click="handleItemEdit"></el-button>
							<el-button type="primary" v-if="isAuth('sys:dict:type:delete')" icon="el-icon-delete" @click="handleItemDelete"></el-button>
						</el-button-group>
					</div>
					<el-input v-model="filterText" placeholder="请输入字典项进行筛选" style="margin-bottom:30px;" />
					<el-scrollbar wrap-class="scrollbar-wrapper" style="height: 600px;">
						<el-tree :highlight-current="true" ref="tree" :data="treeData"
							:props="{children: 'children', label: 'dictName'}" :filter-node-method="filterNode"
							class="filter-tree" @node-click="handleNodeClick" />
					</el-scrollbar>
				</el-card>
			</el-col>
			<el-col :span="18">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>字典子项</span>
						<el-button-group style="float: right;">
							<el-button type="primary" v-if="isAuth('sys:dict:data:save')" icon="el-icon-plus" @click="handleAdd"></el-button>
						</el-button-group>
					</div>
					<el-table v-loading="tabLoading" :data="tabData" element-loading-text="Loading..." border fit
						highlight-current-row row-key="id">
						<el-table-column label="字典码" prop="dictValue" width="110"></el-table-column>
						<el-table-column label="字典值" width="200" align="center" prop="dictLabel"></el-table-column>
						<el-table-column label="备注" align="center" prop="remark"></el-table-column>
						<el-table-column label="排序号" width="110" align="center" prop="sort"></el-table-column>
						<el-table-column label="操作" width="180" align="center">
							<template slot-scope="scope">
								<el-button-group>
									<el-button type="primary" size="mini" v-if="isAuth('sys:dict:data:update')" icon="el-icon-edit" @click="handleEdit(scope.row)">修改
									</el-button>
									<el-button type="danger" size="mini" v-if="isAuth('sys:dict:data:delete')" icon="el-icon-delete" @click="handleDelete(scope.row)">删除
									</el-button>
								</el-button-group>
							</template>
						</el-table-column>
					</el-table>
				</el-card>
			</el-col>
		</el-row>

		<!--  字典项新增、修改弹出框  -->
		<el-dialog :title="dialogTitle" :visible.sync="editItemDialogVisible" width="30%" :close-on-click-modal="false">
			<el-form ref="formItem" :model="currentDictNode" label-width="80px" :rules="rules">
				<el-form-item label="数据代码" prop="dictType">
					<el-input v-model="currentDictNode.dictType"></el-input>
				</el-form-item>
				<el-form-item label="代码名称" prop="dictName">
					<el-input v-model="currentDictNode.dictName"></el-input>
				</el-form-item>
				<el-form-item label="排序号" prop="sort">
					<el-input-number v-model="currentDictNode.sort"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="editItemDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="onSubmit" :loading="editButtonLoading">确 定</el-button>
			</div>
		</el-dialog>

		<!--  字典值新增、修改弹出框  -->
		<el-dialog :title="dialogTitle" :visible.sync="editDialogVisible" width="30%" :close-on-click-modal="false">
			<el-form ref="form" :model="currentDict" label-width="80px" :rules="rules">
				<!--        <el-form-item label="字典名称" prop="name">-->
				<!--          <el-input v-model="currentDict.name"></el-input>-->
				<!--        </el-form-item>-->
				<el-form-item label="字典类别" prop="dictTypeId">
					<el-select disabled v-model="currentDict.dictTypeId" placeholder="请选择">
						<el-option v-for="item in treeData" :key="item.id" :label="item.dictName" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="字典名称" prop="dictLabel">
					<el-input v-model="currentDict.dictLabel"></el-input>
				</el-form-item>
				<el-form-item label="字典码" prop="dictValue">
					<el-input v-model="currentDict.dictValue"></el-input>
				</el-form-item>
				<el-form-item label="排序号" prop="sort">
					<el-input-number v-model="currentDict.sort"></el-input-number>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input type="textarea" v-model="currentDict.remark"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="editDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="onSubmit" :loading="editButtonLoading">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				filterText: '',
				treeData: [],
				tabData: [],
				tabLoading: false,

				dialogTitle: '',
				editDialogVisible: false,
				currentDict: {},
				editButtonLoading: false,
				rules: {
					dictType: [{
						required: true,
						message: '请输入字典码',
						trigger: 'blur'
					}],
					dictName: [{
						required: true,
						message: '请输入代码名称',
						trigger: 'blur'
					}],
					dictLabel:[{
						required: true,
						message: '请输入字典名称',
						trigger: 'blur'
					}],
					dictValue: [{
						required: true,
						message: '请输入字典码',
						trigger: 'blur'
					}],
				},

				currentDictNode: {},

				editItemDialogVisible: false,
			}
		},
		created() {
			this.getTreeData();
		},
		methods: {
			async getTreeData() {
				let param = {
					page: 1,
					limit: 100,
					order: 'asc'
				}
				// let res = await this.$post('sys/bizcatelog/list', param, "", "", true);
				let res = await this.$get('/platform-admin/dict/type/page', param);
				if (res.code == 0) {
					this.treeData = res.data.list;
				}
			},
			handleNodeClick(data) {
				this.currentDictNode = data;
				this.getTabData(data.id)
			},
			filterNode(value, data) {
				if (!value) return true
				return data.dictName.indexOf(value) !== -1
			},
			async getTabData(id) {
				this.tabLoading = true;
				let param={
					page:1,
					limit:99,
					dictTypeId:id
				}
				let res = await this.$get('/platform-admin/dict/data/page', param);
				if (res.code == 0) {
					this.tabData = res.data.list;
				}
				this.tabLoading = false;
			},
			handleItemAdd() {
				this.currentDictNode = {};
				this.dialogTitle = '新增';
				this.editItemDialogVisible = true;
			},
			handleItemEdit() {
				if (this.currentDictNode.dictType == null) {
					this.$message.warning('请选择一个字典项进行修改');
					return;
				}

				this.dialogTitle = '编辑';
				this.editItemDialogVisible = true;
			},
			handleItemDelete() {
				if (this.currentDictNode.dictType == null) {
					this.$message.warning('请选择一个字典项进行删除');
					return;
				}
				this.$confirm('确定删除选中的记录?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let res = await this.$del('/platform-admin/dict/type', [this.currentDictNode.id], "", "",
						true);
					if (res.code === 0) {
						this.$message.success('删除成功')
						this.getTreeData();
					} else {
						this.$message.error('删除失败')
					}
				})
			},
			// 新增数据
			handleAdd() {
				this.currentDict = {
					dictTypeId:this.currentDictNode.id
				};
				this.dialogTitle = '新增';
				this.editDialogVisible = true;

				if (this.currentDictNode.code != null) {
					this.currentDict.type = this.currentDictNode.code;
				}
			},
			handleEdit(item) {
				this.currentDict = Object.assign({}, item);
				this.dialogTitle = '编辑';
				this.editDialogVisible = true;
			},
			handleDelete(item) {
				this.$confirm('确定删除选中的记录?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let res = await this.$del('/platform-admin/dict/data', [item.id], "", "", true);
					if (res.code === 0) {
						this.$message.success('删除成功')
					} else {
						this.$message.error('删除失败')
					}
					this.getTabData(this.currentDictNode.id);
				})
			},
			onSubmit() {
				if (this.editItemDialogVisible) {
					this.dictItemSubmit()
				} else if (this.editDialogVisible) {
					this.dictSubmit()
				}
			},
			dictItemSubmit() {
				this.$refs.formItem.validate(async (valid) => {
					if (valid) {
						this.editButtonLoading = true;

						let url = '';
						let res
						if (this.dialogTitle === '新增')
							res = await this.$post('/platform-admin/dict/type', this.currentDictNode, "", "",
								true);
						else
							res = await this.$put('/platform-admin/dict/type', this.currentDictNode, "", "",
								true);

						if (res.code === 0) {
							this.$message.success('保存成功')
							this.getTreeData();
							this.editItemDialogVisible = false;
						} else {
							this.$message.success(res.message)
						}
						this.editButtonLoading = false;
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			dictSubmit() {
				this.$refs.form.validate(async (valid) => {
					if (valid) {
						this.editButtonLoading = true;
						let res
						if (this.dialogTitle === '新增')
							res = await this.$post('/platform-admin/dict/data', this.currentDict, true, "",
							true);
						else
							res = await this.$put('/platform-admin/dict/data', this.currentDict, true, "", true);
						if (res.code === 0) {
							this.$message.success('保存成功')
							this.getTabData(this.currentDictNode.id)
							this.editDialogVisible = false;
						} else {
							this.$message.success(res.message)
						}
						this.editButtonLoading = false;
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			}
		},
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val)
			}
		}
	}
</script>

<style scoped>
	.dialog-footer {
		padding: 20px;
	}

	.content-div {
		padding: 2vh;
		box-sizing: border-box;
	}
</style>
